import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  Grid,
  Typography,
  Modal,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Spinner from '../../../components/utils/spinner';
import ActiveCalIcon from '../../../icons/activecal.svg';
import InActiveCalIcon from '../../../icons/inactivecal.svg';
import CrossIcon from '../../../icons/cross.svg';
import Cross from '../../../tenant/pages/profile/assets/cross.svg';

const MealPlus = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [secondSelected, setSecondSelected] = useState('1');
  const [secondSelected1, setSecondSelected1] = useState('1');
  const weekStyle = {
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '15px 10px',
    borderRadius: '15px',
    boxShadow: '0px 4px 4px 0px #00000026',
    marginBottom: '15px',
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
    height: '119px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #F5F6F8',
    transition: 'all ease 0.3s',
    '&:hover': {
      opacity: 0.8,
    },
  };
  const weekNewStyle = {
    borderRadius: '10px',
    paddingY: '10px',
    height: 'auto',
    flexGrow: 1,
    maxWidth: '100%',
    fontWeight: '400',
  };
  const weekStyleActive = {
    backgroundColor: '#F0645B',
    padding: '15px 10px',
    boxShadow: 'none',
    borderColor: '#F0645B',
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {false ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            boxShadow: '0px 4px 4px 0px #00000026',
            overflowY: 'auto',
            padding: '15px',
          }}
        >
          <Select
            value={secondSelected}
            onChange={(event) => setSecondSelected(event.target.value)}
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Outfit',
              background:
                'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
              height: '45px',
              border: '1px solid #B0B0B0',
              lineHeight: '20.16px',
              marginBottom: '17px',
              borderRadius: '10px',
              width: 350,
              backdropFilter: 'blur(20px)',
              '& .MuiSelect-icon': {
                top: 'calc(50% - 12px)',
                right: '10px',
              },
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: 0,
                },
            }}
            IconComponent={ExpandMoreIcon}
          >
            <MenuItem value="1">Plan Name</MenuItem>
            <MenuItem value="2">Abc</MenuItem>
            <MenuItem value="3">Xyz</MenuItem>
          </Select>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap', // Ensures responsiveness when the screen size is smaller
              gap: '1.2em',
            }}
          >
            {/* First Box */}
            <Grid
              item
              sx={{
                display: 'flex',
                gap: '1.5em',
                paddingBottom: '1em', // Add some padding for spacing
                flexGrow: 1,
                maxWidth: '1060px',
              }}
            >
              {/* Left Week */}
              <Grid
                sx={{
                  minWidth: '85px',
                }}
              >
                <Grid
                  sx={{
                    ...weekStyle,
                    borderRadius: '10px',
                    paddingY: '10px',
                    height: 'auto',
                    fontWeight: '700',
                  }}
                >
                  Jan
                </Grid>
                <Grid
                  sx={{
                    ...weekStyle,
                    ...weekStyleActive,
                  }}
                  onClick={handleOpenModal}
                >
                  <img src={ActiveCalIcon} alt="" />
                  <Typography
                    marginBottom={'-4px'}
                    fontSize={16}
                    color={'white'}
                  >
                    Week
                  </Typography>
                  <Typography fontSize={24} color={'white'}>
                    1
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    ...weekStyle,
                  }}
                  onClick={handleOpenModal}
                >
                  <img src={InActiveCalIcon} alt="" />
                  <Typography
                    marginBottom={'-4px'}
                    fontSize={16}
                    color={'#3F2F5B'}
                  >
                    Week
                  </Typography>
                  <Typography fontSize={24} color={'#3F2F5B'}>
                    2
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    ...weekStyle,
                  }}
                  onClick={handleOpenModal}
                >
                  <Typography
                    marginBottom={'-4px'}
                    fontSize={16}
                    color={'#3F2F5B'}
                  >
                    Week
                  </Typography>
                  <Typography fontSize={24} color={'#3F2F5B'}>
                    3
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    ...weekStyle,
                  }}
                  onClick={handleOpenModal}
                >
                  <Typography
                    marginBottom={'-4px'}
                    fontSize={16}
                    color={'#3F2F5B'}
                  >
                    Week
                  </Typography>
                  <Typography fontSize={24} color={'#3F2F5B'}>
                    4
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    ...weekStyle,
                  }}
                  onClick={handleOpenModal}
                >
                  <Typography
                    marginBottom={'-4px'}
                    fontSize={16}
                    color={'#3F2F5B'}
                  >
                    Week
                  </Typography>
                  <Typography fontSize={24} color={'#3F2F5B'}>
                    5
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    ...weekStyle,
                  }}
                  onClick={handleOpenModal}
                >
                  <Typography
                    marginBottom={'-4px'}
                    fontSize={16}
                    color={'#3F2F5B'}
                  >
                    Week
                  </Typography>
                  <Typography fontSize={24} color={'#3F2F5B'}>
                    6
                  </Typography>
                </Grid>
              </Grid>

              {/* Weekly */}
              <Grid
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1em',
                    alignItems: 'flex-start',
                  }}
                >
                  {/* Sunday */}
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '0.2em',
                      flexGrow: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        width: '100%',
                        flexGrow: 1,
                      }}
                    >
                      Sunday
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: ' 100%',
                        flexShrink: 1,
                        flexGrow: 1,
                        maxWidth: '100%',
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow: '0px 4px 4px 0px #00000026',
                        maxWidth: '131px',
                        width: ' 100%',
                        flexShrink: 1,
                        height: '119px',
                        maxWidth: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '0.3em',
                          marginTop: 'auto',
                        }}
                      >
                        <Box
                          sx={{
                            ...weekStyle,
                            borderRadius: '10px',
                            paddingY: '3px',
                            marginBottom: 0,
                            backgroundColor: '#63BE5D',
                            height: 'auto',
                            borderColor: 'transparent',
                          }}
                        >
                          <Typography
                            color={'#ffffff'}
                            fontWeight={500}
                            fontSize={14}
                          >
                            B
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            ...weekStyle,
                            borderRadius: '10px',
                            paddingY: '3px',
                            marginBottom: 0,
                            backgroundColor: '#ffffff',
                            height: 'auto',
                            borderColor: 'transparent',
                          }}
                        >
                          <Typography
                            color={'#545496'}
                            fontWeight={500}
                            fontSize={14}
                          >
                            L
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            ...weekStyle,
                            borderRadius: '10px',
                            paddingY: '3px',
                            marginBottom: 0,
                            backgroundColor: '#FF6B00',
                            height: 'auto',
                            borderColor: 'transparent',
                          }}
                        >
                          <Typography
                            color={'#ffffff'}
                            fontWeight={500}
                            fontSize={14}
                          >
                            D
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                          marginTop: 'auto',
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          07
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Monday */}
                  <Grid
                    sx={{
                      rowGap: '0.2em',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        width: '100%',
                      }}
                    >
                      Monday
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow: '0px 4px 4px 0px #00000026',
                        maxWidth: '131px',
                        width: ' 100%',
                        flexShrink: 1,
                        maxWidth: '100%',
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      rowGap: '0.2em',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        width: '100%',
                      }}
                    >
                      Tuesday
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: '100%',
                        maxWidth: '100%',
                        flexShrink: 1,
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      rowGap: '0.2em',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        width: '100%',
                      }}
                    >
                      Wednesday
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: ' 100%',
                        maxWidth: '100%',
                        flexShrink: 1,
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      rowGap: '0.2em',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        width: '100%',
                      }}
                    >
                      Thursday
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: ' 100%',
                        maxWidth: '100%',
                        flexShrink: 1,
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      rowGap: '0.2em',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        width: '100%',
                      }}
                    >
                      Friday
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: ' 100%',
                        maxWidth: '100%',
                        flexShrink: 1,
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      rowGap: '0.2em',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        width: '100%',
                        flexGrow: 1,
                      }}
                    >
                      Saturday
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: ' 100%',
                        maxWidth: '100%',
                        flexShrink: 1,
                        flexGrow: 1,
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: ' 100%',
                        maxWidth: '100%',
                        flexShrink: 1,
                        flexGrow: 1,
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      sx={{
                        ...weekStyle,
                        ...weekNewStyle,
                        justifyItems: 'flex-start',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        padding: '7px',
                        boxShadow:
                          '0px 4px 4px 0px rgba(240, 100, 91, 40%), 0px 2px 4px 0px rgba(0, 0, 0, 10%)',
                        maxWidth: '131px',
                        width: ' 100%',
                        maxWidth: '100%',
                        flexShrink: 1,
                        flexGrow: 1,
                      }}
                    >
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={600}
                        fontSize={11}
                      >
                        Rajma Rice.
                      </Typography>
                      <Typography
                        color={'#3F2F5B'}
                        fontWeight={400}
                        fontSize={10}
                        lineHeight={'15px'}
                        marginBottom={2}
                        style={{
                          opacity: 0.5,
                        }}
                      >
                        Lahori Murgh Chanay, with Special Naan
                      </Typography>
                      <Box
                        sx={{
                          ...weekStyle,
                          borderRadius: '10px',
                          paddingY: '3px',
                          height: 'auto',
                          marginBottom: 0,
                        }}
                      >
                        <Typography
                          color={'#3F2F5B'}
                          fontWeight={500}
                          fontSize={14}
                        >
                          31
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Second Box */}
            <Grid
              item
              sx={{
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  overflow: 'hidden',
                  width: '100%',
                  height: '100%',
                  borderRadius: '15px',
                  boxShadow: '0px 4px 4px 0px #00000026',
                  overflowY: 'auto',
                  padding: '15px',
                  maxWidth: '370px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '15px',
                  }}
                >
                  <Typography fontSize={22} fontWeight={700} color={'#2F2F3B'}>
                    Week 1
                  </Typography>
                  <Button
                    sx={{
                      borderWidth: 1,
                      borderColor: '#F0645B',
                      borderStyle: 'solid',
                      minWidth: '74px',
                    }}
                  >
                    Reset
                  </Button>
                </Box>

                <Typography fontSize={14} fontWeight={700} color={'#3F2F5B'}>
                  31 Oct, 2024
                </Typography>
                <Select
                  value={secondSelected1}
                  onChange={(event) => setSecondSelected1(event.target.value)}
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    fontFamily: 'Outfit',
                    background:
                      'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
                    height: '45px',
                    border: '1px solid #F4F1F8',
                    lineHeight: '20.16px',

                    borderRadius: '10px',
                    width: '100%',
                    backdropFilter: 'blur(20px)',
                    marginY: '15px',
                    marginTop: '10px',
                    '& .MuiSelect-icon': {
                      top: 'calc(50% - 12px)',
                      right: '10px',
                    },
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                      {
                        border: 0,
                      },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: 0,
                      },
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  <MenuItem value="1">Rajma Rice</MenuItem>
                  <MenuItem value="2">Rajma Rice 1</MenuItem>
                  <MenuItem value="3">Rajma Rice w</MenuItem>
                </Select>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '15px',
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: '#F0645B',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '7px',
                      padding: '10px 15px',
                      borderRadius: '100px',
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: '#F0645B',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={'#ffffff'}
                    >
                      Meal name here
                    </Typography>
                    <img src={CrossIcon} alt="" />
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#F0645B',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '7px',
                      padding: '10px 15px',
                      borderRadius: '100px',
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: '#F0645B',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={'#ffffff'}
                    >
                      Meal name here
                    </Typography>
                    <img src={CrossIcon} alt="" />
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#F0645B',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '7px',
                      padding: '10px 15px',
                      borderRadius: '100px',
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: '#F0645B',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={'#ffffff'}
                    >
                      Meal name here
                    </Typography>
                    <img src={CrossIcon} alt="" />
                  </Button>
                </Box>

                <Typography fontSize={14} fontWeight={700} color={'#3F2F5B'}>
                  1 Nov, 2024
                </Typography>
                <Select
                  value={secondSelected1}
                  onChange={(event) => setSecondSelected1(event.target.value)}
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    fontFamily: 'Outfit',
                    background:
                      'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
                    height: '45px',
                    border: '1px solid #F4F1F8',
                    lineHeight: '20.16px',

                    borderRadius: '10px',
                    width: '100%',
                    backdropFilter: 'blur(20px)',
                    marginY: '15px',
                    marginTop: '10px',
                    '& .MuiSelect-icon': {
                      top: 'calc(50% - 12px)',
                      right: '10px',
                    },
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                      {
                        border: 0,
                      },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        border: 0,
                      },
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  <MenuItem value="1">Rajma Rice</MenuItem>
                  <MenuItem value="2">Rajma Rice 1</MenuItem>
                  <MenuItem value="3">Rajma Rice w</MenuItem>
                </Select>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '15px',
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: '#F0645B',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '7px',
                      padding: '10px 15px',
                      borderRadius: '100px',
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: '#F0645B',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={'#ffffff'}
                    >
                      Meal name here
                    </Typography>
                    <img src={CrossIcon} alt="" />
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#F0645B',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '7px',
                      padding: '10px 15px',
                      borderRadius: '100px',
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: '#F0645B',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={'#ffffff'}
                    >
                      Meal name here
                    </Typography>
                    <img src={CrossIcon} alt="" />
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#F0645B',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '7px',
                      padding: '10px 15px',
                      borderRadius: '100px',
                      justifyContent: 'center',
                      '&:hover': {
                        backgroundColor: '#F0645B',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={700}
                      color={'#ffffff'}
                    >
                      Meal name here
                    </Typography>
                    <img src={CrossIcon} alt="" />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '385px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Are You Sure?
            </Typography>
            <img src={Cross} alt="Close" onClick={handleCloseModal} />
          </div>

          <Typography
            variant="h6"
            sx={{
              marginBottom: '0',
              fontSize: '14px',
              fontFamily: 'Outfit',
              fontWeight: '400',
              color: '#4F4D55',
            }}
          >
            You want to change meal plan for this week?
          </Typography>

          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#F0645B',
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MealPlus;
