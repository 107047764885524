import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import alertRed from '../../../icons/alertNotification.svg';
import alertYellow from '../../../icons/alertNotificationYellow.svg';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { TENANT_URL } from "../../../config";

const Notification = () => {
  const { searchVal } = useOutletContext();
  const [older, setOlder] = useState([]);
  const [recent , setRecent] = useState([])
  console.log('recent', recent,older)
  const notifications = [
    {
      id: 1,
      message: 'Your lunch box for today has been received',
      time: '9min ago',
      status: 'recent',
    },
    {
      id: 2,
      message: 'Your lunch box for today has been received',
      time: '14min ago',
      status: 'recent',
    },
    {
      id: 3,
      message: 'Your lunch box for today has been received',
      time: 'Mar 29, 2024',
      status: 'older',
    },
    {
      id: 4,
      message: 'Your lunch box for today has been received',
      time: 'Mar 29, 2024',
      status: 'older',
    },
    {
      id: 5,
      message: 'Your lunch box for today has been received',
      time: 'Mar 29, 2024',
      status: 'older',
    },
  ];

  const fetchDataSubscription = async (url) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("resssss###",response.data)
      setOlder(response.data.recent || []); // Set empty array if no data
      setRecent(response.data.older || [])
      // setCurrPage(response)
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchDataSubscription('/customer/app/notifications');
  }, []);

  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        height: '120vh',
      }}
    >
      {/* Recent Notifications */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 'bold', marginRight: 2, fontSize: '16px' }}
        >
          Recent
        </Typography>
        <Divider sx={{ flex: 1, height: '1px', backgroundColor: '#E2E0E1' }} />
      </Box>
      <List>
      {recent.length === 0 ? (
  <Typography
    variant="body1"
    sx={{ textAlign: 'center', fontWeight: '500', fontSize: '16px', color: 'gray' }}
  >
    No new notification found
  </Typography>
) : (
  recent.map((notif, index) => (
    <ListItem
      key={notif.id}
      sx={{
        boxShadow: index === 0 && '0px 4px 30px 0px #00000014',
        borderRadius: index === 0 && '16px',
        padding: index === 0 ? '30px 18px' : '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          sx={{
            marginRight: 2,
            backgroundColor: index % 2 === 0 ? 'transparent' : '#FFF4DE',
            height: '48px',
            width: '48px',
            border: index === 0 && '1px solid #F0645B',
          }}
        >
          <img
            src={index % 2 === 0 ? alertRed : alertYellow}
            width={14}
            height={17}
            alt="Notification Icon"
          />
        </IconButton>
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              marginBottom: '4px',
            }}
          >
            {notif.title || "Notification"}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: '400', fontSize: '12px' }}
          >
            {notif.message}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            textAlign: 'end',
            fontWeight: '500',
            fontSize: '14px',
            color: '#3F2F5B',
          }}
        >
          {notif.relative_time}
        </Typography>
        {index === 0 && (
          <CircleIcon sx={{ color: '#FFA800', fontSize: 'small' }} />
        )}
      </Box>
    </ListItem>
  ))
)}

      </List>

      {/* Older Notifications */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '18px',
          marginBottom: '6px',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 'bold', marginRight: 2 }}
        >
          Older
        </Typography>
        <Divider sx={{ flex: 1, height: '2px', backgroundColor: '#E2E0E1' }} />
      </Box>
      <List>
      {older.length === 0 ? (
  <Typography
    variant="body1"
    sx={{
      textAlign: 'center',
      fontWeight: '500',
      fontSize: '16px',
      color: 'gray',
      marginTop: '16px',
    }}
  >
    No new notification found
  </Typography>
) : (
  older.map((notif, index) => (
    <ListItem
      key={notif.id}
      sx={{
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          sx={{
            marginRight: 2,
            backgroundColor: index % 2 === 0 ? '#FFF4DE' : '#FFEFF1',
            height: '48px',
            width: '48px',
          }}
        >
          <img
            src={index % 2 === 0 ? alertYellow : alertRed}
            width={14}
            height={17}
            alt="Notification Icon"
          />
        </IconButton>
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              marginBottom: '4px',
            }}
          >
            {notif.title || "Notification"}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: '400', fontSize: '12px' }}
          >
            {notif.message|| ""}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          textAlign: 'end',
          fontWeight: '500',
          fontSize: '14px',
          color: '#3F2F5B',
        }}
      >
        {notif.time || ''}
      </Typography>
    </ListItem>
  ))
)}

      </List>
    </Box>
  );
};

export default Notification;
