import React from 'react';
import Notification from './notifications/notification';
import { Box, Typography } from '@mui/material';
import Pagination from './paymentHistory/pagination';

const Notifications = () => {
  const notificationsdat = [
    { data: 'Payment Due', date: 'May 22, 2022 11:00 AM' },
    { data: 'Summer Campaign 2022', date: 'May 12, 2022 08:00 AM' },
    { data: 'Summer Feast Invite', date: 'April 15, 2022 11:00 AM' },
    { data: 'Eid Special Discount', date: 'March 22, 2022 11:00 AM' },
  ];
  return (
    <>
      <Box
        sx={{
          // display:'none',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 10px 10px 10px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
          borderRadius: '10px',
          boxShadow: '0px 0px 4px 0px #00000026',
          padding: '20px',
          height: { lg: '630px', xl: '710px', md: '1608px' },
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
          Notifications Sent
        </Typography>
        <Box
          sx={{
            overflowY: 'auto',
            height: '100%',
          }}
        >
          {notificationsdat.map((noti, index) => (
            <Notification key={index} data={noti.data} date={noti.date} />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          py: 0.5,
          marginTop: '15px',
        }}
      >
        <Pagination />
      </Box>
    </>
  );
};

export default Notifications;
