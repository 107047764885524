import React from 'react';
import { Box, Button, Typography, Modal, Link } from '@mui/material';
import { ReactComponent as CrossIcon } from '../cross.svg';
import { ReactComponent as Logo } from '../logo.svg';
import { Formik, Form, Field } from 'formik';

const LoginModal = ({ open, onClose }) => {
  const modalCss = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '25px 39px',
    maxHeight: '90vh',
    overflowY: 'auto',
  };

  // Initial values for Formik
  const initialValues = {
    userName: '',
    password: '',
  };

  const handleSubmit = (values) => {
    console.log(values);
    // Handle form submission
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalCss}>
        <div className="d-flex justify-content-end align-items-end mb-3">
          {/* Close icon */}
          <CrossIcon
            onClick={onClose}
            style={{
              cursor: 'pointer',
            }}
          />
        </div>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Logo />
          <Typography
            variant="h6"
            sx={{
              marginBottom: '15px',
              fontSize: '34px',
              fontFamily: 'Outfit !important',
              fontWeight: '700',
              color: '#3F2F5B',
              marginTop: '20px',
              marginBottom: '30px',
              textTransform: 'capitalize',
            }}
          >
            Login To Your Account!
          </Typography>

          {/* Formik Form */}
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, handleBlur }) => (
              <Form
                style={{
                  width: '100%',
                }}
              >
                {/* UserName Field */}
                <Field
                  name="userName"
                  disabled={false} // Update with appropriate logic
                >
                  {({ field, meta }) => (
                    <div>
                      <input
                        {...field}
                        type="email"
                        placeholder="Email"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          height: '51px',
                          width: '100%',
                          marginBottom: '15px',
                          paddingLeft: '20px',
                          borderWidth: 1,
                          outline: 'none',
                          boxShadow: 'none',
                          fontFamily: 'Outfit',
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div style={{ color: 'red' }}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                {/* Password Field */}
                <Field
                  name="password"
                  disabled={false} // Update with appropriate logic
                >
                  {({ field, meta }) => (
                    <div>
                      <input
                        {...field}
                        type="password"
                        placeholder="Password"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          height: '51px',
                          width: '100%',
                          marginBottom: '15px',
                          paddingLeft: '20px',
                          borderWidth: 1,
                          outline: 'none',
                          boxShadow: 'none',
                          fontFamily: 'Outfit',
                        }}
                      />
                      {meta.touched && meta.error && (
                        <div style={{ color: 'red' }}>{meta.error}</div>
                      )}
                    </div>
                  )}
                </Field>

                {/* Login Button */}
                <Box
                  sx={{
                    textAlign: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    sx={{
                      fontSize: '19px',
                      fontWeight: '500',
                      backgroundColor: '#F0645B',
                      minWidth: '188px',
                      height: '51px',
                      borderRadius: '100px',
                      boxShadow: 'none',
                      textTransform: 'capitalize',
                      fontFamily: 'Outfit !important',
                    }}
                  >
                    Login
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>

          {/* SignUp Link */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '3px',
              marginTop: '20px',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '17px',
                fontFamily: 'Outfit',
                fontWeight: '400',
                color: '#3F2F5B',
                fontFamily: 'Outfit !important',
              }}
            >
              Don’t have an account?
            </Typography>
            <Link
              sx={{
                fontSize: '17px',
                fontFamily: 'Outfit',
                fontWeight: '600',
                color: '#3F2F5B',
                fontFamily: 'Outfit !important',
                transition: 'all ease 0.3s',
                '&:hover ': {
                  color: '#F0645B',
                },
              }}
              href={'javascript:void(0)'}
            >
              SignUp
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginModal;
