import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../../../admin/components/customButton/CustomButton';
import { useRoute } from '../../../customHooks/useRoute';
import PaymentModalInputTextArea from '../../customers/components/modals/paymentModalIInputs/paymentModalInputDescription';
import WebInputSelectCountry from '../webListDisplay/components/WebInputSelectCountry';
import axios from 'axios';
import { TENANT_URL } from '../../../../config';
import { useSnackbar } from '../../../../components/utils/snackbarProvider/SnackbarContextProvider';
import { validateDateTime } from '@mui/x-date-pickers/internals';
import AutocompleteAddressInput from '../../../../components/utils/autocompleteAddressInput/autocompleteAddressInput';
import ModalInputText from './ModalInputText';
import CustomerModalInputText from '../../customers/components/modals/CustomerModalInputs/CustomerModalInputText';
import { ReactComponent as TaxIcon } from '../../../assets/tax.svg';

const RouteAddForm = ({ userName, setUserName }) => {
  const { show } = useSnackbar();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [webSetting, setWebSetting] = useState(null);
  const [currencyList, setCurrencyList] = useState([]);

  const handleSubmit = (values) => {
    var model = {
      title: values.title,
      currency: values.currencyD,
      timezone: '1234567890',
      country: values.country,
      state: values.province,
      area: values.city,
      zip_code: values.zip,
      address: values.address,
      large_logo: '3',
      small_logo: '3',
      favicon: '3',
      longitude: values.longitude,
      latitude: values.latitude,
    };

    let data = JSON.stringify(model);

    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${TENANT_URL}/web/setting/${userName}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        show('Data Saved');
      })
      .catch((error) => {
        console.log(error);
        show('Not Data Saved', 'error');
      });
  };

  const schema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short').max(30, 'Too Long').required('*'),
    userName: Yup.string()
      .min(2, 'Too Short')
      .max(30, 'Too Long')
      .required('*'),
    address: Yup.string()
      .min(2, 'Too short')
      .max(150, 'Too long')
      .required('*'),
    unit: Yup.string()
      .max(10, 'Too long')
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric '),
    province: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Only alphabetic characters ')
      .max(30, 'Too long')
      .required('*'),
    city: Yup.string().max(30, 'Too long').required('*'),
    zip: Yup.string()
      .test('zip-validation', function (value) {
        const { country } = this.parent;

        const patterns = {
          canada: /^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/i,
          usa: /^\d{5}$/,
          'united states': /^\d{5}$/,
          india: /^\d{6}$/,
          uk: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
          'united kingdom': /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
          australia: /^\d{4}$/,
          'new zealand': /^\d{4}$/,
          pakistan: /^\d{5}$/,
        };
        const errorMessage = `Invalid ${country} zip format`;

        if (country) {
          const patternCountry = patterns[country.toLowerCase()];
          if (patternCountry) {
            const isValid = patternCountry.test(value);
            if (!isValid) {
              return this.createError({ message: errorMessage });
            }
            return true;
          }
        }

        const defaultPattern =
          /^(?:(?:[A-Z0-9]{6})|(?:[A-Z0-9]{3}\s?[A-Z0-9]{3})|(?:\d{5})|(?:\d{6})|(?:[A-Z]{2}\d{1,2}\s?\d[A-Z]{2})|(?:\d{4}))$/i;
        const isValidDefault = defaultPattern.test(value);
        if (!isValidDefault) {
          return this.createError({ message: 'Invalid zip format' });
        }
        return true;
      })
      .required('*'),
  });

  const fetchCurrencies = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/get/currency`,
    };

    axios
      .request(config)
      .then((response) => {
        setCurrencyList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchUserName = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/tenant/id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      setUserName(response.data);
      const websetting = {
        method: 'get',
        url: `${TENANT_URL}/web/setting/${response.data}`,
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data: res } = await axios(websetting);
      setWebSetting(res.data);
      setCurrentCurrency(res.data.currency);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCurrencies();
    fetchUserName();
  }, []);
  useEffect(() => {
    fetchCurrencies();
    fetchUserName();
  }, []);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: '100%',
          height: 'auto',
          padding: '24px',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          borderRadius: '15px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        }}
      >
        {webSetting && (
          <Box>
            <Formik
              initialValues={{
                title: webSetting ? webSetting.title : '',
                userName: webSetting ? webSetting.tenant_id : '',
                address: webSetting ? webSetting.address : '',

                currencyD: webSetting ? webSetting.currency : '',
                province: webSetting ? webSetting.state : '',
                city: webSetting ? webSetting.area : '',
                zip: webSetting ? webSetting.zip_code : '',
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {(props) => {
                const {
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  resetForm,
                  values,
                } = props;

                console.log(values);
                return (
                  <Form
                    onSubmit={handleSubmit}
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      gap: '24px',
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '24px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          gap: '24px',
                        }}
                      >
                        <Typography variant="h5" fontWeight="500">
                          Service Provider Settings
                        </Typography>
                        <Box
                        // sx={{
                        //   overflowY: 'auto',
                        //   height: 'calc(100vh - 475px)',
                        // }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="title"
                                label="Title"
                                placeholder="Title"
                                onChange={handleChange}
                                component={ModalInputText}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="userName"
                                disabled
                                label="UserName"
                                placeholder="userName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={ModalInputText}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                name="address"
                                label="Address"
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                placeholder="Enter Address"
                                component={AutocompleteAddressInput}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                component={WebInputSelectCountry}
                                list={currencyList}
                                currentVal={currentCurrency}
                                setCurrentCountry={setCurrentCurrency}
                                label={'Currency'}
                                name="currencyD"
                                styleCustom={{
                                  height: '46px',
                                }}
                              />
                            </Grid>

                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="province"
                                label="Province"
                                placeholder="Province"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={CustomerModalInputText}
                              />
                            </Grid>
                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="city"
                                label="City"
                                placeholder="City"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={ModalInputText}
                              />
                            </Grid>

                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="zip"
                                label="Zip"
                                placeholder="A1A 1A1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={ModalInputText}
                              />
                            </Grid>

                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="taxname"
                                label="Tax Name"
                                placeholder="Enter Tax Name"
                                component={ModalInputText}
                              />
                            </Grid>

                            <Grid xs={12} sm={6} item>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    height: '46px',
                                  },
                                }}
                                name="taxvalue"
                                label="Tax Value"
                                placeholder="Enter Tax Value"
                                component={ModalInputText}
                              />
                            </Grid>

                            <Grid xs={12} sm={12} item>
                              <Link
                                href="javascript:void(0)"
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '6px',
                                  alignItems: 'center',
                                  color: '#AC9EC3',
                                  fontWeight: '500',
                                  transition: 'all ease 0.3s',

                                  '&:hover': {
                                    color: '#f1645b',
                                  },
                                }}
                              >
                                <TaxIcon />
                                Add New Tax Field
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: '20px',
                        }}
                      >
                        <Button
                          variant="outline"
                          onClick={resetForm}
                          sx={{
                            color: '#AC9EC3',
                            textDecoration: 'underline',
                            fontWeight: 500,
                            minWidth: 60,
                          }}
                        >
                          Reset
                        </Button>

                        <Button
                          variant="filled"
                          type="button"
                          onClick={() => props.submitForm()}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        )}
      </Box>
    </>
  );
};

export default RouteAddForm;
