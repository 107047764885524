import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Grid,
  Typography,
  Modal,
  Menu,
  Switch,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/utils/spinner';
import Cross from '../../../tenant/pages/profile/assets/cross.svg';
import TrialMealCards from './components/cards';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from './components/paginations';
import SearchTextField from '../../../customer/components/SearchTextField';
import { ReactComponent as UploadIcon } from '../../../icons/upload.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormModalInputText from './components/formfied/inputfield';
import FormTextarea from './components/formfied/texreafiels';

const MealPlus = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Add Modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Edit Modal
  const [openModal1, setOpenModal1] = useState(false);
  const handleOpenModal1 = () => {
    setOpenModal1(true);
  };
  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };

  // Delete Modal
  const [openModal2, setOpenModal2] = useState(false);
  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  //
  const customerColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.value}</div>
      ),
    },
    {
      field: 'created_by',
      headerName: 'Created by',
      flex: 1,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.value}</div>
      ),
    },
    {
      field: 'created_on',
      headerName: 'Created on',
      flex: 1,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.value}</div>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      headerAlign: 'left',
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px' }}>{params.value}</div>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      headerClassName: 'header-cell',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <div style={{ fontSize: '13px', textAlign: 'left' }}>
          ${params.value}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 5,
      headerClassName: 'header-cell',
      cellClassName: 'column-cell',
      renderCell: (params) => (
        <>
          <div
            style={{
              backgroundColor: '#54BA4A',
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              marginRight: '5px',
            }}
          />
          {params.value}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 80,
      headerClassName: 'header-cell',
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'column-cell',
      renderCell: (params) => {
        return (
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="filled"
              onClick={handleClick}
              sx={{
                textTransform: 'capitalize',
                padding: 0, // Remove any extra padding
                minWidth: 88, // Fixed width for the circle
                height: '40px', // Fixed height for the circle
                borderRadius: 1000, // Circle shape
                fontWeight: 400,
                color: 'white',
                boxShadow: 'none',
                '&:focus': {
                  outline: 0,
                },
              }}
            >
              Actions
            </Button>

            <Box ref={menuRef}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    minWidth: 100,
                    marginTop: '8px',
                    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.04)', // Add shadow here
                    borderRadius: '10px',
                  },

                  '& .MuiMenuItem-root': {
                    padding: '5px 16px',
                    color: '#7E6E9A',
                    fontSize: '12px',
                    fontWeight: 500,
                    width: '100%',
                  },
                }}
              >
                <MenuItem sx={{ width: '100%', my: 0.1, fontSize: '12px' }}>
                  View
                </MenuItem>
                <MenuItem
                  onClick={handleOpenModal1}
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                >
                  Edit
                </MenuItem>

                <MenuItem
                  onClick={handleOpenModal2}
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </div>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      name: 'Veg',
      created_by: 'Lorem ipsum dolor',
      created_on: 'Jan 23, 2023',
      description:
        'Lorem ipsum dolor sit amet consectetur. Sed aenean augue in viverra.',
      price: '138',
      status: 'Active',
    },
    {
      id: 2,
      name: 'Veg',
      created_by: 'Lorem ipsum dolor',
      created_on: 'Jan 23, 2023',
      description:
        'Lorem ipsum dolor sit amet consectetur. Sed aenean augue in viverra.',
      price: '138',
      status: 'Active',
    },
    {
      id: 3,
      name: 'Veg',
      created_by: 'Lorem ipsum dolor',
      created_on: 'Jan 23, 2023',
      description:
        'Lorem ipsum dolor sit amet consectetur. Sed aenean augue in viverra.',
      price: '138',
      status: 'Active',
    },
    {
      id: 4,
      name: 'Veg',
      created_by: 'Lorem ipsum dolor',
      created_on: 'Jan 23, 2023',
      description:
        'Lorem ipsum dolor sit amet consectetur. Sed aenean augue in viverra.',
      price: '138',
      status: 'Active',
    },
    {
      id: 5,
      name: 'Veg',
      created_by: 'Lorem ipsum dolor',
      created_on: 'Jan 23, 2023',
      description:
        'Lorem ipsum dolor sit amet consectetur. Sed aenean augue in viverra.',
      price: '138',
      status: 'Active',
    },
    {
      id: 6,
      name: 'Veg',
      created_by: 'Lorem ipsum dolor',
      created_on: 'Jan 23, 2023',
      description:
        'Lorem ipsum dolor sit amet consectetur. Sed aenean augue in viverra.',
      price: '138',
      status: 'Active',
    },
  ];

  //////
  const menuRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {false ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <TrialMealCards />
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              borderRadius: '15px',
              boxShadow: '0px 4px 4px 0px #00000026',
              overflowY: 'auto',
              padding: '15px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SearchTextField
                newStyle={{
                  backgroundColor: '#ffffff',
                }}
              />
              <Button
                variant="filled"
                onClick={handleOpenModal}
                sx={{
                  textTransform: 'capitalize',
                  padding: 0, // Remove any extra padding
                  minWidth: 100, // Fixed width for the circle
                  height: '40px', // Fixed height for the circle
                  borderRadius: 1000, // Circle shape
                  fontWeight: 400,
                  color: 'white',
                  boxShadow: 'none',
                  '&:focus': {
                    outline: 0,
                  },
                }}
              >
                + Add New
              </Button>
            </Box>
            <Grid
              container
              sx={{
                marginTop: '14px',
              }}
            >
              <DataGrid
                sx={{
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& .MuiDataGrid-withBorderColor': {
                    border: 'none',
                  },
                  '& .column-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    borderBottom: '1px solid white',
                  },
                  '& .header-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    width: '100%',

                    borderBottom: '3px solid white',
                  },
                  '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'normal',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '--unstable_DataGrid-radius': 'none',
                  borderTop: '1px solid white',
                }}
                rows={rows}
                columns={customerColumns}
                hideFooter
                disableColumnFilter
                // checkboxSelection
                disableColumnSelector

                // autoPageSize={true}
              />
            </Grid>
          </Box>
          <Box>
            <Pagination />
          </Box>
        </>
      )}

      {/* Add Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '767px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Add Trial Meal
            </Typography>
            <img
              src={Cross}
              style={{
                cursor: 'pointer',
              }}
              alt="Close"
              onClick={handleCloseModal}
            />
          </div>

          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Meal Name'}
                placeholder={'Enter Meal Name'}
                type={'text'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Meal Price'}
                placeholder={'Enter Meal Price'}
                type={'text'}
              />
            </Grid>
          </Grid>
          <FormTextarea
            label={'Meal Name'}
            placeholder={'Enter Meal Name'}
            type={'textarea'}
          />
          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                marginRight: 'auto',
              }}
            >
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="start"
                sx={{
                  marginLeft: '0px',
                }}
              />
            </Box>
            <Button
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '3px',
              }}
            >
              <UploadIcon />
              <Typography
                variant="h6"
                sx={{
                  marginBottom: '0',
                  fontSize: '14px',
                  fontFamily: 'Outfit',
                  fontWeight: '500',
                  color: '#AC9EC3',
                  textDecoration: 'underline',
                }}
              >
                Upload Image
              </Typography>
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#AC9EC3',
                color: '#AC9EC3',
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
                color: '#ffffff',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal open={openModal1} onClose={handleCloseModal1}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '767px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Edit Trial Meal
            </Typography>
            <img
              src={Cross}
              style={{
                cursor: 'pointer',
              }}
              alt="Close"
              onClick={handleCloseModal}
            />
          </div>

          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Meal Name'}
                placeholder={'Enter Meal Name'}
                type={'text'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Meal Price'}
                placeholder={'Enter Meal Price'}
                type={'text'}
              />
            </Grid>
          </Grid>
          <FormTextarea
            label={'Meal Name'}
            placeholder={'Enter Meal Name'}
            type={'textarea'}
          />
          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                marginRight: 'auto',
              }}
            >
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Status"
                labelPlacement="start"
                sx={{
                  marginLeft: '0px',
                }}
              />
            </Box>
            <Button
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '3px',
              }}
            >
              <UploadIcon />
              <Typography
                variant="h6"
                sx={{
                  marginBottom: '0',
                  fontSize: '14px',
                  fontFamily: 'Outfit',
                  fontWeight: '500',
                  color: '#AC9EC3',
                  textDecoration: 'underline',
                }}
              >
                Upload Image
              </Typography>
            </Button>
            <Button
              onClick={handleCloseModal1}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#AC9EC3',
                color: '#AC9EC3',
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
                color: '#ffffff',
              }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Modal */}
      <Modal open={openModal2} onClose={handleCloseModal2}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '385px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '25px 15px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <div className="d-flex justify-content-between align-items-end mb-3">
            <Typography
              variant="h6"
              sx={{
                marginBottom: '0',
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: '500',
                color: '#3F2F5B',
              }}
            >
              Are You Sure?
            </Typography>
            <img src={Cross} alt="Close" onClick={handleCloseModal} />
          </div>

          <Typography
            variant="h6"
            sx={{
              marginBottom: '0',
              fontSize: '14px',
              fontFamily: 'Outfit',
              fontWeight: '400',
              color: '#4F4D55',
            }}
          >
            You want to delete trial meal plan?
          </Typography>

          {/* Footer Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 4,
            }}
          >
            <Button
              onClick={handleCloseModal2}
              variant="outlined"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                borderColor: '#F0645B',
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: '14px',
                fontWeight: '500',
                backgroundColor: '#F0645B',
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MealPlus;
