import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import CancelIcon from '@mui/icons-material/Cancel';
// import RouteAddForm from '../../../routeAddForm/routeAddForm';
import { useUtility } from '../../../useUtility';
import { Box, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../../../../../admin/components/customButton/CustomButton';
import { useRoute } from '../../../../../customHooks/useRoute';
import CustomerModalInputText from '../../../../customers/components/modals/CustomerModalInputs/CustomerModalInputText';
import PaymentModalInputTextArea from '../../../../customers/components/modals/paymentModalIInputs/paymentModalInputDescription';
// import RouteSwitch from '../../../routeSwitch/routeSwitch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SwitchButton from '../../../../foodItem/components/modals/formModal/formModalInputs/switch-button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AddNewRouteDialogeBox({ Open, setOpen, permission }) {
  const {
    setItemIdForUpdate,
    itemIdForUpdate,
    isEditModalOpen,
    setIsEditModalOpen,
  } = useUtility();
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [checked, setChecked] = useState(true);
  const { addRoute, getRouteById, updateRoute } = useRoute();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const routeNameRef = useRef(null);
  const routeAreaRef = useRef(null);
  const routeDescriptionRef = useRef(null);

  const resetInputs = () => {
    routeNameRef.current.value = null;
    routeAreaRef.current.value = null;
    routeDescriptionRef.current.value = null;
    setChecked(true);
  };
  useEffect(() => {
    getUpdateItem();
  }, [Open]);
  const getUpdateItem = async () => {
    if (itemIdForUpdate && Open) {
      setLoading(true);

      await getRouteById(itemIdForUpdate).then((item) => {
        setChecked(item.data.is_active);
        setDefaultObjectForUpdate(item.data);
      });
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    if (!itemIdForUpdate) {
      addRoute(values.name, values.area, values.description, checked);
    } else {
      updateRoute(
        values.name,
        values.area,
        values.description,
        checked,
        itemIdForUpdate
      );
    }
    setOpen(false);
    resetInputs();
  };

  const schema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short').max(30, 'Too Long').required('*'),

    area: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('*'),

    description: Yup.string()
      .max(160, 'Too long')
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric ')
      .required('*'),

    // routeName: Yup.string()
    //   .max(16, 'Route Name is too long')
    //   .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric characters allowed')
    //   .required('Required'),

    // routeArea: Yup.string()
    //   .max(16, 'Route Area is too long')
    //   .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric characters allowed')
    //   .required('Required'),
  });
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_route_tab');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };
  const CatchFromParent = (v) => {
    setChecked(v);
  };
  // const catchLoading=(v)=>{
  //   alert(v)
  //   setLoading(v)
  // }

  const RouteListGridHeaderButton = ({ children, onClick, disable }) => {
    return (
      <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
        <Button
          onClick={handleClickOpen}
          disabled={disable}
          variant="filled"
          sx={{
            mx: isMdUp ? 1 : 0,
            my: !isMdUp ? 0.5 : 0,

            width: !isMdUp ? '100%' : 'auto',
            borderRadius: '20px',
            textTransform: 'capitalize',
            px: { md: 3, lg: 3, sm: 2, xs: 1 },
            fontWeight: 400,
            color: 'white',
            boxShadow: 'none',
            marginRight: '10px',
          }}
        >
          {children}
        </Button>
      </Box>
    );
  };

  return (
    <div style={{ borderRadius: '45px' }}>
      <RouteListGridHeaderButton
        disable={!permissions.add}
        onClick={handleClickOpen}
      >
        + Add New
      </RouteListGridHeaderButton>

      <BootstrapDialog
        PaperProps={{
          sx: { borderRadius: '15px', padding: '0px', width: '900px' },
        }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Open && !loading}
      >
        {/* <Box>
          {permissions.add && <RouteAddForm  open={Open} handleLoading={(v)=>catchLoading(v)} />}
          </Box> */}
        <Box
          sx={{
            width: { xs: '100%' },
            marginX: 'auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              width: '100%',
              py: 1,
              borderRadius: '15px',
            }}
          >
            <Box
              sx={{
                marginX: '25px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  paddingY: '6px',
                }}
              >
                <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
                  {itemIdForUpdate ? 'Update Route' : 'Add New Route'}
                </Typography>
                <Box
                  sx={{
                    ml: 'auto',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={handleClose}
                >
                  <CancelIcon
                    alt="closeIcon"
                    style={{ fontSize: '35px', color: '#ff7676' }}
                    sx={{
                      transition: 'box-shadow 0.3s',
                      borderRadius: '50px',
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                        WebkitBoxShadow:
                          '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                        MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: '10px',
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: itemIdForUpdate
                      ? defaultObjectForUpdate.route_name
                      : '',
                    area: itemIdForUpdate
                      ? defaultObjectForUpdate.route_area
                      : '',
                    description: itemIdForUpdate
                      ? defaultObjectForUpdate.description
                      : '',
                  }}
                  // validator={() => ({})}
                  validationSchema={schema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {(props) => {
                    const {
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      resetForm,
                      values,
                    } = props;
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              name="name"
                              label="Route Name"
                              placeholder="Route Name"
                              passedRef={routeNameRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={CustomerModalInputText}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              name="name"
                              label="Route Charges ($)"
                              placeholder="Charges"
                              passedRef={routeNameRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={CustomerModalInputText}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Box>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                  },
                                }}
                                name="area"
                                label="Route Area"
                                placeholder="Route Area"
                                passedRef={routeAreaRef}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={CustomerModalInputText}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ marginTop: '-5px' }}>
                              <Field
                                name="description"
                                label="Description"
                                placeholder="Enter Short Description"
                                passedRef={routeDescriptionRef}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={PaymentModalInputTextArea}
                                minRows={4}
                                multiline
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Box sx={{ marginTop: '-16px' }}>
                                <SwitchButton
                                  parentStatusCheck={CatchFromParent}
                                  checked={checked}
                                  setChecked={setChecked}
                                />
                              </Box>

                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  mb: '15px',
                                  alignItems: 'center',
                                  gap: '24px',
                                }}
                              >
                                {!itemIdForUpdate ? (
                                  <Button
                                    variant="unfilled"
                                    onClick={resetForm}
                                  >
                                    Reset
                                  </Button>
                                ) : (
                                  ''
                                )}

                                <Button variant="filled" type="submit">
                                  Save
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box>
      </BootstrapDialog>
    </div>
  );
}
