import { Box, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfileCard from './components/profileCard';
import { useProfile } from '../../customHooks/useProfile';
import Spinner from '../../../components/utils/spinner';
import DataCards from './components/dataCards';
import PaymentHistory from './components/paymentHistory';
import OrderHistory from './components/orderHistory';
import Notifications from './components/notifications';
import UpgradeSubscription from './components/upgradeSubscription';
import './assets/customerProfileScrollBar/profileScrollBar.css';
import UpdateProfileCardModal from './components/updateProfileCard/updateProfileCardModal';
import NoteCard from './components/NoteCard';

const CustomerProfile = () => {
  const { loading, currentCustomerData } = useProfile();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (currentCustomerData && Object.keys(currentCustomerData).length > 0) {
      setOpen(true);
    }
  }, [currentCustomerData]);

  // Tabs
  const [value, setValue] = React.useState('one'); // Active tab state

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Define the tab styles function
  const tabStyles = (isActive) => ({
    fontSize: 16,
    textTransform: 'none', // Prevent all caps
    fontWeight: 'bold',
    backgroundColor: isActive ? '#F0645B' : 'transparent', // Active tab background
    color: isActive ? '#ffffff' : '#3F2F5B', // Active tab text color
    '&:hover': { backgroundColor: '#F0645B', color: '#ffffff' }, // Hover effect
    borderRadius: 100,
    '&.Mui-selected': {
      color: '#ffffff', // Ensures selected text color is applied
    },
    height: 'auto',
    minHeight: 'auto',
    padding: '14px 18px',
    marginRight: '12px',
    '@media (max-width: 600px)': {
      minWidth: 0, // Prevent fixed width
      fontSize: '12px', // Smaller font size for mobile
      padding: '4px 8px',
    },
  });

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <UpdateProfileCardModal setOpen={setOpen} open={open} />
          <Box>
            <Box
              sx={{
                fontFamily: "'Roboto', sans-serif",
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mx: 'auto',
                backgroundColor:
                  'linear-gradient(106deg, rgb(237 16 16 / 37%) 0%, rgba(228, 245, 255, 0) 100%)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '30px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    maxWidth: '410px',
                  }}
                >
                  <Grid container rowSpacing={'30px'}>
                    <Grid item xs={12}>
                      <ProfileCard setOpen={setOpen} />
                    </Grid>
                    <Grid item xs={12}>
                      <NoteCard />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                  }}
                >
                  <DataCards />
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { display: 'none' }, // Remove default indicator
                    }}
                    variant="scrollable"
                    aria-label="scrollable"
                    allowScrollButtonsMobile
                    sx={{
                      borderRadius: 0,
                      overflow: 'hidden',
                      marginTop: '15px',
                      '.MuiTabScrollButton-root': {
                        display: 'flex', // Ensure buttons are visible
                        color: '#ff6600', // Change arrow color
                        backgroundColor: '#FFF0E5', // Button background
                        borderRadius: '5px', // Rounded buttons
                        height: 40,
                        top: 2,
                        '&:hover': {
                          backgroundColor: '#FFF0E5', // Hover effect
                        },
                      },
                      '.MuiTabs-indicator': {
                        display: 'none',
                      },
                      '.Mui-disabled': {
                        display: 'none',
                      },
                    }}
                  >
                    <Tab
                      value="one"
                      label="Upgrade Subscription"
                      sx={tabStyles(value === 'one')}
                    />
                    <Tab
                      value="two"
                      label="Schedule"
                      sx={tabStyles(value === 'two')}
                    />
                    <Tab
                      value="three"
                      label="Order History"
                      sx={tabStyles(value === 'three')}
                    />
                    <Tab
                      value="four"
                      label="Payment History"
                      sx={tabStyles(value === 'four')}
                    />
                    <Tab
                      value="fifth"
                      label="Notifications Sent"
                      sx={tabStyles(value === 'fifth')}
                    />
                  </Tabs>
                  {/* Tab Content */}
                  <Box
                    sx={{
                      paddingBottom: 0,
                    }}
                  >
                    {value === 'one' && (
                      <div className="palletBox">
                        <UpgradeSubscription />
                      </div>
                    )}
                    {value === 'two' && (
                      <div className="palletBox">
                        <Typography
                          sx={{
                            fontFamily: 'Outfit',
                            fontSize: '22px',
                            fontWeight: '500',
                            lineHeight: '28px',
                            letterSpacing: '0.3px',
                            textAlign: 'left',
                            color: 'black',
                          }}
                        >
                          Add Calender
                        </Typography>
                      </div>
                    )}
                    {value === 'three' && (
                      <div className="palletBox">
                        <OrderHistory />
                      </div>
                    )}
                    {value === 'four' && (
                      <div className="palletBox">
                        <PaymentHistory />
                      </div>
                    )}
                    {value === 'fifth' && (
                      <div className="palletBox">
                        <Notifications />
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CustomerProfile;
