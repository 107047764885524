import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
  Divider,
  OutlinedInput,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import availableAmount from '../../../icons/availableAmount.svg';
import couponCode from '../../../icons/couponCode.svg';
import dollor from '../../../icons/dollor.svg'
import currentPlan from '../../../icons/currentPlan.svg'
import oldPlan from '../../../icons/oldPlan.svg'
import AdditionalMenuModal from './AdditionalMenuModal';
import axios from 'axios';
import { TENANT_URL } from '../../../config';

const Subscription = () => {
  const [open, setOpen] = useState(false);
  const [data,setData] = useState({})
  const [updatePlan, setUpdatePlan] = useState({
    payment: 0,
    menu_plan_id: '',
    subscription_plan: '',
    delivery_time_slot: '',
  });

  console.log("helloooooo((9",updatePlan)
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpgradeSubscription = async() =>{
    const url = '/customer/profile'
    const endpoint = `${TENANT_URL}${url}`; 
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.put(endpoint,updatePlan, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("successfully updateddd",response)
      // handleClose()
     
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }

  }

  const fetchDataSubscription = async(url) =>{
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = "174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e"
    console.log('$$$$authToken', authToken)
    try {

      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      setData(response.data.data)

      console.log("responseeeee----",response)
      
    } catch (error) {
      
    }
  }

  // const updateSubscription = async() =>{
  //   let url = '/customer/profile'
  //   const endpoint = `${TENANT_URL}${url}`;
  //   const authToken = "174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e"
  //   console.log('$$$$authToken', authToken)
  //   try {

  //     const response = await axios.put(endpoint, {
        
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });

  //     setData(response.data.data)

  //     console.log("responseeeee----",response)
      
  //   } catch (error) {
      
  //   }
    
  // }

  useEffect(()=>{
    fetchDataSubscription('/customer/profile')

  },[])
  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        height: '120vh',
      }}
    >
      {/* <Typography variant="h4" gutterBottom>
        Upgrade Subscription
      </Typography> */}

      <Grid container spacing={6}>
        {/* Upgrade Subscription Heading */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6" // Makes it consistent as a heading
            sx={{
              fontWeight: 600, // Bold text
              fontSize:'16px',
              marginBottom: '16px', // Add spacing below the heading
              color: '#333', // Consistent color,
              textTransform: "uppercase"
            }}
          >
            Upgrade Subscription
          </Typography>
          
          {/* Upgrade Subscription Form */}
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Meal Category*
            </Typography>
            <Select
              fullWidth
              defaultValue={data?.plan_category_name}
              

              className="cust-select"
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
              <MenuItem value={data?.plan_category_name} >{data?.plan_category_name}</MenuItem>
              {/* <MenuItem value="category2">Category 2</MenuItem> */}
            </Select>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Meal*
              <Typography display={'inline'} sx={{textDecoration: "underline", color:"#F0645B"}}> Additional items </Typography>
            </Typography>
            <Select
              fullWidth
              defaultValue={data?.meal_type}
              value={updatePlan?.menu_plan_id}
              onChange={(e) => setUpdatePlan({ ...updatePlan, menu_plan_id: e.target.value })}
              className="cust-select"
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
              <MenuItem value={data?.menu_plan_id}>{data?.meal_type}</MenuItem>
              {/* <MenuItem value="category2">Category 2</MenuItem> */}
            </Select>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Subscription Plan*
            </Typography>
            <Select
              fullWidth
              defaultValue={data?.subscription_details?.plan_name}
              value={updatePlan?.subscription_plan}
              onChange={(e) => setUpdatePlan({ ...updatePlan, subscription_plan: e.target.value })}
              className="cust-select"
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
              <MenuItem value={data?.subscription_details?.plan_name}>{data?.subscription_details?.plan_name}</MenuItem>
              {/* <MenuItem value="category2">Category 2</MenuItem> */}
            </Select>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Available Slot*
            </Typography>
            <Select
              fullWidth
              defaultValue=""
              value={updatePlan?.delivery_time_slot}
              onChange={(e) => setUpdatePlan({ ...updatePlan, delivery_time_slot: e.target.value })}
              className="cust-select"
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
                border: '1px solid #EBEBEC', // Add custom border
                borderRadius: '10px', // Optional: adjust border-radius if needed
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none', // Remove the default outline
                },
                '.MuiSelect-icon': {
                  color: '#7E7E7E', // Change the icon color
                },
              }}
            >
              <MenuItem value={data?.delivery_slot}>{data?.delivery_slot}</MenuItem>
              {/* <MenuItem value="category2">Category 2</MenuItem> */}
            </Select>
          </Box>
          {/* <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Add-Ons*
            </Typography>
            <Select
              fullWidth
              defaultValue=""
              className="cust-select"
              IconComponent={ExpandMoreIcon}
              sx={{
                padding: '4px',
                fontSize: '14px',
                height: '36px',
              }}
            >
              <MenuItem value="category1">Category 1</MenuItem>
              <MenuItem value="category2">Category 2</MenuItem>
            </Select>
          </Box> */}
        </Grid>

        {/* Available Plans Heading */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600, // Match Upgrade Subscription style
              fontSize:'16px',
              marginBottom: '22px',
              color: '#333',
              textTransform: "uppercase"
            }}
          >
            Available Plans
          </Typography>

          {/* Available Plans Content */}
          {/* <Box
      sx={{
        backgroundColor: "#f9f9f9", // Light background
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "16px",
      }}
    > */}
          <Card
            sx={{
              marginBottom: '20px',
              boxShadow: '0px 6px 5px 0px #080F340F',
              border: '1px solid #EBEBEC',
              borderRadius: '10px',
            }}
          >
            <CardContent>
              <Grid container >
                <Grid item container sx={{paddingBottom:'12px'}}>
                  <img src={currentPlan} height={24} width={24}/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  // spacing={2}
                  sx={{ paddingBottom: '6px' }}
                >
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      Current Plan Details
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 700,textAlign:'end' }}
                    >
                      ${data?.plan_details?.plan_price}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingTop: '0px', marginBottom: '12px' }}>
                  <Typography variant="body1" sx={{ color: '#4F4D55' }}>
                    {data?.plan_details?.item_names}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{ paddingBottom: '6px' }}
              >
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 600 }}
                  >
                    Addon
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 700,textAlign:'end' }}
                  >
                    $11
                  </Typography>
                </Grid>
              </Grid> */}
              {/* <Typography
                variant="body1"
                sx={{ marginBottom: '12px', color: '#4F4D55' }}
              >
                Bread, Water, Juice, Tea. Coffee
              </Typography> */}
              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{ paddingBottom: '6px' }}
              >
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 600 }}
                  >
                    Additional Items
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" sx={{ color: '#4F4D55' }}>
                {data?.additional_plan_details?.items}
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              marginBottom: '20px',
              boxShadow: '0px 6px 5px 0px #080F340F',
              border: '1px solid #EBEBEC',
              borderRadius: '10px',
            }}
          >
            <CardContent>
            <Grid item container sx={{paddingBottom:'12px'}}>
                  <img src={oldPlan} height={24} width={24}/>
                </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={10}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      Old Plan Details
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '14px', fontWeight: 700,textAlign:'end' }}
                    >
                      $0
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sx={{ paddingTop: '0px', marginBottom: '12px' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      fontStyle: 'italic',
                      color: '#9E9E9E',
                    }}
                  >
                    You don’t have any older plan
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} container spacing={2}>
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 600 }}
                  >
                    Addon
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', fontWeight: 700,textAlign:'end' }}
                  >
                    $0
                  </Typography>
                </Grid>
              </Grid> */}
                {/* <Typography
                  variant="body1"
                  sx={{
                    marginBottom: '12px',
                    fontSize: '13px',
                    fontWeight: 500,
                    fontStyle: 'italic',
                    color: '#9E9E9E',
                  }}
                >
                  You don’t have any older plan
                </Typography> */}
            </CardContent>
          </Card>
          {/* </Box> */}
        </Grid>
      </Grid>

      {/* Payment Detail */}
      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h6" gutterBottom>
          PAYMENT DETAIL
        </Typography>
        <Grid container spacing={2} sx={{marginTop:'24px', marginBottom:'30px'}}>
          <Grid item xs={6} md={3}>
            <Card>
              <Grid container sx={{ padding: '17px 18px' }}>
                <Grid item xs={2} sx={{ paddingTop: 0 }}>
                  <img src={availableAmount} height={27} width={27} />
                </Grid>
                <Grid item xs={10} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" sx={{ fontSize: '15.7px',color:'#3F2F5B' }}>
                    Available Amount: ${data?.account_balance}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={6} md={3}>
            <Card>
              <Grid container sx={{ padding: '17px 18px' }}>
                <Grid item xs={2} sx={{ paddingTop: 0 }}>
                  <img src={availableAmount} height={27} width={27} />
                </Grid>
                <Grid item xs={10} sx={{ paddingTop: 0 }}>
                  <Typography variant="body1" sx={{ fontSize: '15.7px',color:'#3F2F5B' }}>
                    Due Amount: ${data?.due_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={6} md={3}>
            <Card sx={{borderRadius:'10px'}}>
              <Grid container >
                <Grid
                  item
                  xs={3}
                  sx={{
                    backgroundColor: '#F0645B',
                    minHeight: '61px',
                    display: 'flex', // Enables flexbox
                    justifyContent: 'center', // Centers horizontally
                    alignItems: 'center',
                    borderRadius:'10px'
                  }}
                >
                  <img src={couponCode} height={27} width={27} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body1" sx={{ fontSize: '16px',padding:'19px 10px',color:'#3F2F5B' }}>
                    Coupon Code:
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card sx={{borderRadius:'10px'}}>
              <Grid container >
                <Grid
                  item
                  xs={3}
                  sx={{
                    backgroundColor: '#F0645B',
                    minHeight: '61px',
                    display: 'flex', // Enables flexbox
                    justifyContent: 'center', // Centers horizontally
                    alignItems: 'center',
                    borderRadius:'10px'
                  }}
                >
                  <img src={dollor} height={27} width={27} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body1" sx={{ fontSize: '16px',padding:'19px 10px',color:'#3F2F5B' }}>
                    Payment Amount: $0
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Buttons */}
      <Box sx={{ marginTop: '20px', display:'flex', gap:"22px", justifyContent:'end' }}>
      <Button variant="contained" sx={{backgroundColor:"#F0645B"}} onClick={handleUpgradeSubscription}>
          Update
        </Button>
        <Button
          variant="outlined"
          sx={{ marginRight: '10px', border:'1px solid #F0645B', color:'#F0645B' }}
          onClick={()=> setOpen(true)}
        >
          Add Additional Items
        </Button>
       
      </Box>
      <AdditionalMenuModal
      open={open}
      handleClose={handleClose}
      />
    </Box>
  );
};

export default Subscription;
