import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Chip,
  IconButton,
  Badge,
} from '@mui/material';
import { color, styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

/** Custom Styled Accordion */
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: '4px', // Add padding
  boxShadow: 'none', // Remove default shadow
  '&:before': {
    display: 'none', // Remove default top divider
  },
  '& .MuiAccordionSummary-root': {
    borderTop: `1px solid ${theme.palette.divider}`, // Only show top border
  },
  '& .MuiAccordionSummary-root:last-child': {
    borderBottom: 'none', // Remove bottom border from last item
  },
  // "& .MuiAccordionDetails-root": {
  //   paddingTop: "16px", // Adjust details padding
  //   paddingBottom: "16px",
  // },
  '& .MuiPaper-root': {
    padding: '0px',
  },
  '& .MuiButtonBase-root': {
    padding: '4px 0px',
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
  },
  '& .MuiChip-root': {
    backgroundColor: '#F0645B',
  },
  '& .MuiTypography-root': {
    display: 'flex',
    alignItems: 'center',
    gap: '28px',
  },
  '& .MuiBadge-badge': {
    color: 'white',
    height: '28px',
    width: '32px',
    borderRadius: '30px',
    backgroundColor: '#F0645B',
  },
  '& .MuiSvgIcon-root': {
    color: 'rgb(255, 255, 255) !important',
  },
  '& .MuiChip-label': {
    color: 'white',
  },
}));

const AdditionalMenuModal = ({ open, handleClose }) => {
  const [mealItems, setMealItems] = useState({
    Monday: ['Meal name here', 'Meal name here', 'Meal name here'],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: ['Meal name here'],
    Sunday: ['Meal name here', 'Meal name here'],
  });

  console.log("neeeeewwwMeal", mealItems)

  const [additionalMenu, setAdditionalMenu] = useState([]);
  console.log('additionalMenu', additionalMenu);
  const [loading, setLoading] = useState(true);

  const handleUpdateAdditional = async() =>{
    const url = '/customer/app/additional-menu'
    const endpoint = `${TENANT_URL}${url}`; 
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.put(endpoint,{
        custom_menu: {...mealItems}
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("successfully updateddd",response)
      handleClose()
     
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }

  }

  const fetchDataSubscription = async (url, setter) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setter(response.data.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  const fetchDataMealsItem = async (url, setter) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = '174|Gm06Ly58Qmt9rg8Xw0YUoJuVivQxBXe519XlERTT9016ee2e';
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("#####",response)
      setter(response.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await fetchDataMealsItem('/customer/app/additional-menu', (data) => {
        // Populate mealItems state with fetched data (example structure assumed)
        setMealItems((prev) => ({
          ...prev,
          Monday: data?.monday?.items || data?.Monday?.items || [],
          Tuesday: data?.tuesday?.items || data?.Tuesday?.items || [],
          Wednesday: data?.wednesday?.items || data?.Wednesday?.items || [],
          Thursday: data?.thursday?.items || data?.Thursday?.items || [],
          Friday: data?.friday?.items || data?.Friday?.items || [],
          Saturday: data?.saturday?.items || data?.Saturday?.items || [],
          Sunday: data?.sunday?.items || data?.Sunday?.items || [],
        }));
      });

      await fetchDataSubscription('/customer/app/items/all', setAdditionalMenu);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleAddMeal = (day, meal) => {
    if (meal && !mealItems[day].includes(meal)) { // Check if the meal already exists
      setMealItems((prev) => ({
        ...prev,
        [day]: [...prev[day], meal],
      }));
    }
  };

  const handleDeleteMeal = (day, meal) => {
    setMealItems((prev) => ({
      ...prev,
      [day]: prev[day].filter((item) => item !== meal),
    }));
  };

  const getTotalItems = () =>
    Object.values(mealItems).reduce(
      (total, dayItems) => total + dayItems.length,
      0
    );

  const getAllItems = () => {
    return Object.entries(mealItems).flatMap(([day, items]) =>
      items.map((item) => ({ day, item }))
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Additional Menu Items
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: 16, top: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Ongoing Items Accordion */}
        <StyledAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Ongoing Items{' '}
              <Badge badgeContent={getTotalItems()} color="primary" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
              {getAllItems().map((entry, index) => (
                <Chip
                  key={index}
                  label={`${entry.day}: ${entry.item}`}
                  color="primary"
                />
              ))}
            </div>
          </AccordionDetails>
        </StyledAccordion>

        {/* Individual Days Accordion */}
        {Object.keys(mealItems).map((day, index) => (
          <StyledAccordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {day}{' '}
                <Badge
                  badgeContent={mealItems[day].length}
                  color="primary"
                  style={{ marginLeft: 8 }}
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                {mealItems[day].map((meal, idx) => (
                  <Chip
                    key={idx}
                    label={meal}
                    onDelete={() => handleDeleteMeal(day, meal)}
                    color="secondary"
                  />
                ))}
              </div>
              <TextField
                fullWidth
                placeholder="Select Item and hit enter"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddMeal(day, e.target.value);
                    e.target.value = '';
                  }
                }}
                className="additionalMenuSelect"
                style={{ marginTop: 16 }}
              />
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          gap: '12px',
        }}
      >
        <Button
          onClick={() =>
            setMealItems({
              Monday: [],
              Tuesday: [],
              Wednesday: [],
              Thursday: [],
              Friday: [],
              Saturday: [],
              Sunday: [],
            })
          }
          color="secondary"
          sx={{
            border: '1px solid #F0645B',
            color: '#F0645B',
          }}
        >
          Reset
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{ backgroundColor: '#F0645B' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdditionalMenuModal;
